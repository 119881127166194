interface CandexDTO {
    OKCODE: string
    CALLER: string
    EXT_PRODUCT_ID: string
    DESCRIPTION: string
    EXT_CATEGORY_ID: string
    PRICE: string
    PRICEUNIT: string
    CURRENCY: string
    QUANTITY: string
    LONGTEXT: string
    UNIT: string
    VENDORMAT: string
    MATGROUP: string
    LEADTIME: string
    CUST_FIELD2: string
    CUST_FIELD3: string
    CUST_FIELD5: string
}

export class CandexData {
    constructor(
        // public ExtProductId: string,
        public PrItemTxt: string,
        public PrMaterial: string,
        public PrPriceRaw: number,
        public PrNetPriceRaw:number,
        public PrNetPriceUnit: number,
        public PrCurrency: string,
        public PrQuantityRaw: number,
        // public LongText: string,
        public PrQuantityUom: string,
        public VendorMaterial: string,
        // public PrMaterial: string,
        // public LeadTime: string,
        // public custField2: string,
        // public custField3: string,
        // public custField5: string
    ) {
    }

    static FromBackend(data: CandexDTO): CandexData {
        return new CandexData(
            // data.EXT_PRODUCT_ID,
            data.DESCRIPTION,
            data.EXT_CATEGORY_ID,
            +data.PRICE,
            +data.PRICE,
            +data.PRICEUNIT,
            data.CURRENCY,
            +data.QUANTITY,
            // data.LONGTEXT,
            data.UNIT,
            data.VENDORMAT,
            // data.MATGROUP,
            // data.LEADTIME,
            // data.CUST_FIELD2,
            // data.CUST_FIELD3,
            // data.CUST_FIELD5
        )
    }
}
