import Item from "antd/lib/list/Item";
import { isEmpty } from "ramda";
import DocumentFlow from "src/app/docFlows/domains/documentFlow/documentFlow";
import { PricingConditions } from "src/app/docFlows/domains/documentFlow/pricingConditions";
import { DocFlowItem } from "src/app/docFlows/domains/prpo/poItem";
import { Features } from "src/app/shared/domains/core/pataflag";
import { PataflagService } from "src/app/shared/services/pataflag.service";
import { PriceService2 } from "src/app/shared/services/price.service";


export enum ExceptionEnum {
  EXCEPTION = 'Exception'
}

export const fetchCurrencyConversion = async (showCBbasedOnSupplierStatus: boolean, thresholdCurr: string, prCurrency: string) => {
  // convert amount from prCurrency to threshold currency
  let amount = 1000;
  if (!showCBbasedOnSupplierStatus || thresholdCurr === prCurrency)
    throw Error('No CB, no api call required');

  let result: any = await PriceService2.convertPrice(
    amount,
    prCurrency,
    thresholdCurr
  );

  result = { ...result, amount: amount, prCurrency: prCurrency }

  return result;

};

export function check_CB_For_Rejection(df, firstLineItem, threshold_value, totalValDynamicInThresholdCurr) {

  // We have added this new condition based on 166925 User Story
  if (df?.OB_Rej_Ind === 'R' &&
    (firstLineItem?.Supplier_Status?.trim() === "Validated" || firstLineItem?.Supplier_Status?.trim() === "Blank") &&
    (+totalValDynamicInThresholdCurr >= +threshold_value)
  ) {
    return true;
  }

  return false;
}

export function check_CB_on_ValueChangeAndCurrencyChange(df, firstLineItem, threshold_value, totalValDynamicInThresholdCurr, initialTotalVal, newTotalValue, initialTotavlValInThresholdCurr, initialCurrency, changedCurrency) {

  // We have added this new condition based on 166925 User Story
  if (df?.OB_Rej_Ind === 'R') {
    return check_CB_For_Rejection(df, firstLineItem, threshold_value, totalValDynamicInThresholdCurr);
  }

  // when user just changes the values and if the new total value is less than or equal to initial total value, 
  // applying the below condition.
  if ((initialCurrency === changedCurrency) && (+newTotalValue <= +initialTotalVal)) return false

  // if initial total val in threshold currency is already greater than threashold value
  // then here we do not need CB

  if (+initialTotavlValInThresholdCurr >= +threshold_value) return false;

  // 

  if (+totalValDynamicInThresholdCurr >= +threshold_value) return true


  return false;
}


export const initialTopicValues = [
  {
    value: "Offer no. 1 (selected supplier)",
    label: "Offer no. 1 (selected supplier)"
  },
  { value: "Offer no. 2", label: "Offer no. 2" },
  { value: "Offer no. 3", label: "Offer no. 3" },
  { value: "Event summary", label: "Event summary" }
];

export const exceptionValues = [
  { value: "Contract in place", label: "Contract in place" },
  { value: "Single Source", label: "Single Source" },
  { value: "Emergency case", label: "Emergency case" },
]


export interface filesAdded {
  uploadStatus: "UPLOADING";
  attachedFiles: File;
  attachments: string;
  uniqId: string;
  controller: AbortController;
  fileType: string;
}

export interface fileSelected {
  selectedFiles: File,
  uniqId: string,
  controller: AbortController,
  fileType: string;
}



export const checkIfEmailIsNeeded = (
  index: number,
  filesUploaded: any,
  data: any,
  totalVal: number,
  totalValDynamic: number,
  currencyConversionValue: number,
  AllLinesState: any,
  initialLines: any,
  hasFeature: Function,
  Features: any
) => {
  // this function is to ensure email is sent only on certain conditions
  // if it returns false then no email will be sent

  if (
    JSON.stringify(AllLinesState[index]) ===
    JSON.stringify(initialLines[index])
  )
    return false;

  if (!data.IsEasyRequest) {
    return true;
  } // this func is valid only for easy request PRs

  if (filesUploaded.length > 0) {
    return true;
  } // if files are uploaded send emails by default

  // if its easy pr deletion then no email to be sent
  if (data?.isToBeDeleted === "X") {
    return false
  }

  if (data.SendPDF === "X") return true; // send email if po sending options have been changed

  const propertiesToIgnore = [
    "SupplierStatus",
    "TotalPOAmount",
    "IsCBRequired",
    "CCIndicator",
    "IsEasyRequest",
    "CBFileData"
  ];
  const tempValues = { ...data };
  propertiesToIgnore.forEach(val => delete tempValues[val]);
  const tempData = Object.keys(tempValues);

  // as part of RC_SKIP_EMAIL email is skipped if only del date is changed
  if (
    tempData.length === 1 &&
    tempData[0] === "PrDeliveryDate" &&
    hasFeature(Features.RC_SKIP_EMAIL)
  ) {
    return false;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // this section is to check if only total amount related fields - Quantity, Price, Per Unit were changed
  // if so, and if total value has decreased then dontsend the email
  const fieldsAffectingTotalAmount = [
    "PrQuantityRaw",
    "PrPriceRaw",
    "PrNetPriceRaw",
    "PrNetPriceUnit"
  ];
  let tempValuesToPopOut = [...tempData];
  fieldsAffectingTotalAmount.forEach(item => {
    tempValuesToPopOut = tempValuesToPopOut.filter(el => el !== item);
  });
  if (
    tempValuesToPopOut.length === 0 &&
    totalValDynamic * currencyConversionValue <
    totalVal * currencyConversionValue
  ) {
    return false;
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // but as part of RC_SKIP_EMAIL_FOR_EASY_REQUEST email is skipped if no change is made to the below fields in the requests
  const fieldsToSendEmail = [
    "PrQuantityRaw",
    "PrPriceRaw",
    "PrNetPriceRaw",
    "PrCurrency",
    "PrNetPriceUnit",
    "Pr_wbsid",
    "Pr_CostCenter",
    "Pr_GlAccount",
    "PR_PROFIT",
    "Textsupplier"
  ];
  let shouleEmailBeSent: boolean = false; // it will be enabled if any change is made to any field within fieldsToSendEmail array

  tempData.forEach(item => {
    if (fieldsToSendEmail.filter(el => el === item).length > 0) { shouleEmailBeSent = true };
  })
  if (!shouleEmailBeSent) { return false };

  return true;
};


export function CBBasedOnSupplierStatusFun(values: DocFlowItem, df?: DocumentFlow) {

  const checkIfItsEasyRequestPR = getCondtions(values)?.isItEasyRequestPR;

  if (!checkIfItsEasyRequestPR) return false; // below checks are only for easy request PRs

  // const supplierStatus = values.Supplier_Status.replaceAll(/\s/g,'');

  if (
    values.Supplier_Status?.trim() === "Mandatory" ||
    values.Supplier_Status?.trim() === "Sole Source" || values.Supplier_Status?.trim() === "SoleSource"
  ) {
    return false;
  }

  return true;
}

export const diff = function (obj1, obj2) {
  if (!obj2 || Object.prototype.toString.call(obj2) !== "[object Object]") {
    return obj1;
  }
  // console.log("old :" + JSON.stringify(obj1));
  // console.log("New :" + JSON.stringify(obj2));
  var diffs = {};
  var key;

  const arraysMatch = function (arr1, arr2) {
    if (arr1.length !== arr2.length) return false;

    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  };

  const compare = function (item1, item2, key) {
    var type1 = Object.prototype.toString.call(item1);
    var type2 = Object.prototype.toString.call(item2);

    if (type2 === "[object Undefined]") {
      diffs[key] = null;
      return;
    }

    if (type1 !== type2) {
      diffs[key] = item2;
      return;
    }

    if (type1 === "[object Object]") {
      var objDiff = diff(item1, item2);
      if (Object.keys(objDiff).length > 0) {
        diffs[key] = objDiff;
      }
      return;
    }

    if (type1 === "[object Array]") {
      if (!arraysMatch(item1, item2)) {
        diffs[key] = item2;
      }
      return;
    }

    if (type1 === "[object Function]") {
      if (item1?.toString() !== item2?.toString()) {
        diffs[key] = item2;
      }
    } else {
      if (item1 !== item2) {
        diffs[key] = item2;
      }
    }
  };

  for (key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      compare(obj1[key], obj2[key], key);
    }
  }

  for (key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (!obj1[key] && obj1[key] !== obj2[key]) {
        diffs[key] = obj2[key];
      }
    }
  }

  return diffs;
};
export const convertToString = (value: any) => {
  if (!value) return value;
  const tempValue = typeof (value) === 'string' ? value : value.toString()
  return tempValue;
};

export const compareObj = (sourceItem: Object, latestItem: Object) => {
  let finalObject = {};

  if (!sourceItem) {
    finalObject = latestItem;
    delete finalObject["tableData"]
    return finalObject;
  };
  const latestKeys = Object.keys(latestItem);

  latestKeys.forEach(key => {
    if ((latestItem[key] !== sourceItem?.[key]) && latestItem[key] !== null && latestItem[key] !== undefined && key !== "tableData") {
      finalObject[key] = convertToString(latestItem[key])
    }
  })
  return finalObject;

};

export function isItFoFun(values) {
  return values.PoDocType === "FO";
}

export const formatValue = (value: string) => {
  let tempValue = Number(value.replace(/[\s]/g, '').replace(',', '.'));
  if (isNaN(tempValue)) return 0;
  return tempValue;
};

export const getUnDeletedItems = (allItems: DocFlowItem[]) => {
  return allItems.filter(item => item["isToBeDeleted"] !== "X")
}

export const getDeletedItems = (allItems: DocFlowItem[]) => {
  return allItems.filter(item => item["isToBeDeleted"] === "X")
}

export function getTotalValState(allItems) {
  return getUnDeletedItems(allItems).map(item => item.PrPrice).reduce((a, b) => a + b).toFixed(2);
}

export const removeNullValues = data => {
  let tempData = { ...data };
  Object.keys(tempData).forEach(key => {
    if (tempData[key] == null) {
      // this will remove all values which are null and undefined
      delete tempData[key];
    }
  });
  return tempData;
};

export const convertToNum = (value) => {
  const tempValue = typeof (value) === 'string' ? Number(value.replace(',', '.')) : Number(value);
  return tempValue;
};

export const checkIfValid = (value) => {
  const tempvalue = convertToNum(value);
  if (isNaN(tempvalue) || tempvalue <= 0) { return true }
  return false
};

export const priceInitialValue = (value) => {
  let price = typeof (value) === 'string' ?
    Number(value.replace(/[\s]/g, '').replace(',', '.')) : value;
  return price;
};

export const supplierComments = (item) => {
  return item ? item?.replace(/^,/, '')?.trim() : "";
};

export const getCondtions = (item: DocFlowItem) => {
  const isItFO: boolean = item?.PR_Doctype === "FO"; // check US - 157625 for the conditions 
  const isItZSIM: boolean = item?.MaterialType === "ZSIM";
  const isItEasyRequestPR = item?.ExtReqNo === "EASY_REQUEST_PR" || item?.ExtReqNo?.slice(0, 3) === "EPR";
  const isTherePO: boolean = !isEmpty(item?.PoNo);
  const isTherePR: boolean = !isEmpty(item?.PrNo);
  const isThereGR: boolean = Number((item?.GrQty || "0,00").replace(",", ".")) !== 0;
  const isThereIR: boolean = Number((item?.IrAmount || "0,00").replace(",", ".")) !== 0;
  const isItMulti: boolean = item?.PR_PROFIT?.trim() === "MULTI" ||
    item?.CostCenter?.trim() === "MULTI" ||
    item?.Pr_CostCenter?.trim() === "MULTI" ||
    item?.PR_PROFIT?.trim() === "MULTI" ||
    item?.WbsElementTxt?.trim() === "MULTI" ||
    item?.Pr_WbsElementTxt?.trim() === "MULTI";
  const isThereAccCategory = (item?.PR_AccCategory === "K") || (item?.PR_AccCategory === "P");
  return { isItFO, isItZSIM, isItEasyRequestPR, isTherePO, isTherePR, isThereGR, isThereIR, isItMulti, isThereAccCategory };
}

export const isItCandexVendor = (docFlow: DocumentFlow) => {
  const isItCandexVendor = docFlow.VendorName?.toLowerCase()?.includes("candex");
  return isItCandexVendor;
};

export const calcValueForCandex = (price: number, documentFlow: DocumentFlow, isCandexEnabled:boolean) => {
  const val: number = (isItCandexVendor(documentFlow) && isCandexEnabled) ? +price + (+price * 0.03) : +price;
  return val;
}

export const getAccountsTabDataShowingConditions = (item) => {

  let isShowProfitCenterData = true;
  let isShowCostCenterData = true;
  let isShowGlaAccountData = true;
  let isShowWbsElementData = true;

  if (item.AccCategory === "K") {
    isShowProfitCenterData = false;
    isShowWbsElementData = false;
  }
  if (item.AccCategory === "2") {
    isShowCostCenterData = false;
    isShowWbsElementData = false;
  }
  if (item.AccCategory === "P") {
    if (item.CostCenter === '') {
      isShowCostCenterData = false;
      isShowProfitCenterData = false;
    } else {
      isShowProfitCenterData = false;
    }
  }

  return { isShowProfitCenterData, isShowCostCenterData, isShowGlaAccountData, isShowWbsElementData };
}

export const getResendPoPdfNotificationMessage = (modified, added) => {
  if (modified && added)
    return "Since fields have been modified and new line has been added, PO PDF will be sent to the selected users";
  if (modified)
    return "Since fields have been modified, PO PDF will be sent to the selected users";
  if (added)
    return "Since new line has been added, PO PDF will be sent to the selected users";
  return ""
}

export const removeDecimal = (price: number, decimal: number) => {
  return (decimal === null || decimal > 0) ? price : Number(price.toString().split(".")[0]);
};

export const sortItem = (allItem: DocFlowItem[]) => {
  return allItem.sort((a, b) => {
    if (a.PrItem < b.PrItem) {
      return -1;
    }
    if (a.PrItem > b.PrItem) {
      return 1;
    }
    return 0;
  })
}

export const getFinalLineItems = (allLineItems: any, lineNo, editedValues: any) => {
  let [editedLineItem] = allLineItems.filter(el => el.PrItem === lineNo);
  editedLineItem = { ...editedLineItem, ...editedValues };
  let otherLineItems = allLineItems.filter(el => el.PrItem !== lineNo);
  const newItemLines = [...otherLineItems, editedLineItem];
  return sortItem(newItemLines);
}

export const buildItemsToSend = (accItems: any[], SystemAlias: string, isItEasyRequestPR: boolean) => {
  const tempData = accItems?.map(item => {
    return {
      PrNo: item.PRNo,
      PrItem: item.PRNo_Item,
      SystemAlias: SystemAlias,
      IsEasyRequest: isItEasyRequestPR,
      PR_AccCategory: item.AccCategory,
      Pr_GlAccount: item.GLAccount,
      Pr_CostCenter: item.CostCenter,
      Pr_wbsid: item.Pr_WBSelement,
      PR_PROFIT: item.ProfitCenter,
      Seqno_Acc: item.SequenceNo,
      Quantity_Acc: item.Quantity,
      Dist_Percent: item.DistributionPercent,
      Net_Value: item.NetOrderValue,
      Dist_Indicator: item.DistributionInd,
      Del_indicator: item.Del_indicator
    }
  }
  );
  return tempData
};

export const buildPricingCondsToSend = (pricingConditionData: PricingConditions, SystemAlias: string, isItEasyRequestPR: boolean) => {
  const tempData = {
    PrNo: pricingConditionData.prNo,
    PrItem: pricingConditionData.itemNumber,
    SystemAlias: SystemAlias,
    IsEasyRequest: isItEasyRequestPR,
    Cond_typ: pricingConditionData.condType,
    Cond_text: pricingConditionData.condTypeDesc,
    Cond_seq: pricingConditionData.condItemCounter,
    Cond_per: pricingConditionData?.calculationType === "A" ? pricingConditionData.condRate : "",
    Cond_amt: (pricingConditionData?.calculationType === "B" || pricingConditionData?.calculationType === "C") ? pricingConditionData.condRate : "",
    Cond_qty: pricingConditionData.pricingUnit,
    Cond_uom: pricingConditionData.conditionUnit,
    Cond_curr: pricingConditionData.baseCurr,
    Cond_supp: pricingConditionData.vendorNo,
    Cond_flag: pricingConditionData["Cond_flag"],
    Cond_del: pricingConditionData["Cond_del"],
    Cond_tval: pricingConditionData.condAmtInBaseCurr

  }
  return tempData
};

export const getAddMatFromPslDefaultFilters = (vendorList: any[] = [], plants: any[] = []) => {

  return {
    Suppliers: {
      "data": [],
      "key": "Suppliers",
      "label": "Suppliers",
      "selected": vendorList
    },
    Plants: {
      "data": [],
      "key": "Plants",
      "label": "Plants",
      "selected": plants
    },
    Materials: {
      "data": [],
      "key": "Materials",
      "label": "Material",
      "selected": []
    },
    Status: {
      "data": [],
      "key": "Status",
      "label": "Status",
      "selected": []
    }
  }
}

export enum AddMatFromPslFilterKeys {
  Supplier = 'Suppliers',
  PLANT = 'Plants',
  MATERIAL = 'Materials',
  STATUS = 'Status',
}

export const AddMatFromPslFilters = [
  { key: AddMatFromPslFilterKeys.Supplier, label: 'Supplier' },
  { key: AddMatFromPslFilterKeys.STATUS, label: 'Status' },
  { key: AddMatFromPslFilterKeys.PLANT, label: 'Plant' },
  { key: AddMatFromPslFilterKeys.MATERIAL, label: 'Material' },
];

export const prepareItemsAndAccountsChangesData = (accData, itemsData) => {
  const allData = accData.map((acc) => {
    const data = itemsData.filter(item => item.PrItem === acc.PrItem);
    return { ...data[0], ...acc };
  })
  return allData;
}