
import React, { useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DocumentFlow from 'src/app/docFlows/domains/documentFlow/documentFlow';
import { DocFlowItem } from 'src/app/docFlows/domains/prpo/poItem';
import { getCondtions, removeDecimal } from '../../helper';
import { DISPATCH_TYPES } from '../../EditRequestAllLinesWrapper';
import { useFetchCandexData } from 'src/app/docFlows/hooks/docflow.hooks';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootStateStore } from 'src/app/application.reducers';
import { environment } from 'src/app/shared/domains/core/environment';
import { CandexData } from 'src/app/shared/domains/master/candexData';

type addMaterialFromCandexProps = {
    currencyDecimals: any;
    lineItems: DocFlowItem[];
    documentFlow: DocumentFlow;
    setOpenCandexPopUp: Function;
    dispatchMethod: any;
    sessionId: string
};

export const AddMaterialFromCandex = (props: addMaterialFromCandexProps) => {
    const { lineItems, documentFlow, setOpenCandexPopUp, dispatchMethod, sessionId } = props;

    const firstLineItem = lineItems[0];
    const lastLineItem = lineItems[props.lineItems?.length - 1];
    const fieldLevelConditions = getCondtions(firstLineItem);

    const currentUser = useSelector((state: RootStateStore) => state.shared.user.currentUser);
    const { data: candexData, isLoading, error, isError } = useFetchCandexData(sessionId);

    function handleClose() {
        setOpenCandexPopUp(false)
    };

    useEffect(() => {
        if (candexData && !isLoading) {
            console.log(candexData);
            if (Object.keys(candexData).length > 0) {
                addCandexItem(candexData);
            }
            handleClose();
        }
    }, [candexData, isLoading]);

    const addCandexItem = (item: CandexData) => {
        let tempQty = item.PrQuantityRaw;
        let finalPrice = item.PrPriceRaw || 0;
        let finalTempNetPriceUnit = item.PrNetPriceUnit || 0;
        const newItem = { ...item };

        if (item?.PrCurrency) {
            const tempDecimal: { currency: string, decimal: string } = props.currencyDecimals?.filter(curr => curr.currency === item?.PrCurrency)[0];
            const decimal = tempDecimal?.decimal ? parseInt(tempDecimal?.decimal) : null;
            finalPrice = removeDecimal(item.PrPriceRaw, decimal);
            finalTempNetPriceUnit = removeDecimal(item.PrNetPriceUnit, decimal);
        };

        const newTotal: number = isNaN((finalPrice * tempQty) / finalTempNetPriceUnit) ? 0 : (finalPrice * tempQty) / finalTempNetPriceUnit;
        delete item["status"]; // this is to avoid sending supplier status to sap
        const payload: any = {
            ...lastLineItem,
            ...item,
            CopiedItemNo: lastLineItem.PrItem,
            PrPrice: newTotal,
            PrRequisitioner: documentFlow.Requisitioner,
            PrReqFname: documentFlow.Reqfnam,
            PrReqLname: documentFlow.Reqlnam,
            // PrDeliveryDate: "",
            // PrQuantityRaw: fieldLevelConditions.isItFO ? 1 : "",
            // Taxcode: "",
            isItCopiedLineItem: true, // this is just to ensure system treats this similar to the item added from PSL
            isItAddedFromPsl: true, // this is just to ensure system treats this similar to the item added from PSL
            isSupplierStatusMandatoryOrSoleSource: false,
            // Supplier: item.vendorNo === "999999999" ? documentFlow.VendorNo : item.vendorNo?.padStart(10, '0'),
            GrQty: "0.000",
            IrAmount: "0.000",
        };
        // if (newItem.status === 'Mandatory' || newItem.status === 'SoleSource') {
        //     payload["isSupplierStatusMandatoryOrSoleSource"] = true;
        // }
        if (fieldLevelConditions.isItFO) {
            payload["PrPriceRaw"] = finalPrice;
            delete payload["PrNetPriceRaw"]
        } else {
            payload["PrNetPriceRaw"] = finalPrice;
            delete payload["PrPriceRaw"]
        };
        dispatchMethod({
            type: DISPATCH_TYPES.ADD_NEW_ITEM_LINE,
            payload: payload
        })
    };

    return <Dialog open={true} maxWidth={"lg"} scroll={'paper'} fullWidth={true} style={{ width: "auto", height: "auto" }} closeAfterTransition={false}>
        <div style={{ backgroundColor: "orange" }}>
            <DialogTitle>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: 'center' }}>
                        <span style={{ color: 'white' }}>Add New Line From Candex</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}>
                        <CloseIcon onClick={handleClose} style={{ color: 'white' }}>close</CloseIcon>
                    </div>
                </div>
            </DialogTitle>
        </div>
        <DialogContent>
            <iframe
                id="inlineFrameExample"
                title="Inline Frame Example"
                width="100%"
                height="800"
                loading='lazy'
                style={{ border: "none" }}
                src={`${environment.CANDEX_URL}?UserName=${environment.CANDEX_USER_NAME}&Password=${environment.CANDEX_PASSWORD}&UserEmail=${currentUser.email}&HOOK_URL=${environment.REACT_APP_API_BASE}/api/Candex/SaveDetails?sessionId=${sessionId}`}
            >
            </iframe>
        </DialogContent>
    </Dialog >
};