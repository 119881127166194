
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { ApplyButton } from 'src/app/feedbackCockpit/components/styles';
import DocumentFlow from 'src/app/docFlows/domains/documentFlow/documentFlow';
import { StyledDialogTitle } from 'src/app/shared/components/layout/styles';
import { Dialog, DialogActions } from '@material-ui/core';
import { PricingConditionsTable } from './pricingConditionsTable';
import { useFetchConditionTypes } from 'src/app/shared/domains/master/master.hooks';
import { useFetchCurrencySetFromSAP } from '../../hooks/mulitlineEditPr.hooks';
import { PricingConditions } from 'src/app/docFlows/domains/documentFlow/pricingConditions';
import { DISPATCH_TYPES } from '../../EditRequestAllLinesWrapper';
import { DocFlowItem } from 'src/app/docFlows/domains/prpo/poItem';

type addPricingCondtionsProps = {
    selectedPricingConditonsData: PricingConditions[];
    selectedItem: DocFlowItem;
    documentFlow: DocumentFlow;
    setOpenPricingConditionsPopUp: Function;
    dispatchMethod: any;
};

export enum VendorType {
 DEFAULT = 'DEFAULT',
 CUSTOM = 'CUSTOM',
}

export const AddPricingCondtions = (props: addPricingCondtionsProps) => {
    const { selectedPricingConditonsData, selectedItem, documentFlow, dispatchMethod, setOpenPricingConditionsPopUp, } = props;
    const [pricingConditionsData, setPricingConditionsData] = React.useState<PricingConditions[]>(selectedPricingConditonsData);
    const { data: conditionTypesData, isLoading: conditionTypesLoading } = useFetchConditionTypes(documentFlow.PurchOrg, documentFlow.SystemAlias);
    const { data: currencySet } = useFetchCurrencySetFromSAP(documentFlow?.SystemAlias);
    const [vendorType, setVendorType] = React.useState<VendorType>(VendorType.DEFAULT);
    const [selectedVendor, setSelectedVendor] = React.useState(null);

    function handleClose() {
        setOpenPricingConditionsPopUp(false)
    };

    const calcTotal = (item: PricingConditions) => {
        if (item.calculationType === "B") {
            return {
                condAmtInBaseCurr: item.condRate
            }
        }
        else if (item.calculationType === "A") {
            const total = (+item.condRate * +selectedItem?.PrPrice) / 100;
            return {
                condAmtInBaseCurr: !isNaN(total) ? total?.toString() : "0"
            }
        } else {
            const total = (+item.condRate / +item.pricingUnit) * +selectedItem?.PrQuantityRaw;
            return { condAmtInBaseCurr: !isNaN(total) ? total?.toString() : "0" }
        }
    }

    const handleChange = (values: any, condItemCounter: number) => {
        const tempItem = pricingConditionsData?.map(item => {
            return item?.condItemCounter === condItemCounter ? {
                ...item,
                ...values,
                editedItem: true
            } : item
        });
        const tempItemWithAmt = tempItem?.map(item => {
            const totalAmt = calcTotal(item);
            return {
                ...item,
                ...totalAmt
            }
        })
        setPricingConditionsData(tempItemWithAmt);
    };

    const addPriceConditionsItem = () => {
        const tempItem = PricingConditions.FromBackend({
            ARIBAPRNo: props.documentFlow.AribaPR,
            BaseCurr: selectedItem?.PrQuantityUom ? selectedItem?.PrCurrency : "",
            CalculationType: "",
            ChngeType: "",
            CompCode: props.documentFlow.PRCompanyCode,
            CondAmtInBaseCurr: "",
            CondBaseVal: "",
            CondItemCounter: pricingConditionsData?.length + 1,
            CondRate: "",
            CondType: "",
            CondTypeDesc: "",
            CondValue: "",
            ConditionUnit: selectedItem?.PrQuantityUom,
            Currency: "",
            ItemNumber: selectedItem?.PrItem,
            PoCond_Flag: "",
            PricingDate: "",
            PricingUnit: "",
            SAPPRNo: props.documentFlow.PurchaseReqNo,
            TotalconditionAmt: "",
            VendorNo: props.documentFlow.VendorNo,
            VendorName: props.documentFlow.VendorName,
            __metadata: { id: "", type: "", uri: "" }
        });
        tempItem["Cond_flag"] = "X";
        tempItem["isItInitialItem"] = false;
        tempItem["editedItem"] = true;
        setPricingConditionsData(prevState => [...prevState, tempItem]);
    };

    const handleDelete = (isItInitialItem: boolean, condItemCounter: number) => {

        if (isItInitialItem) {
            handleChange({ Cond_del: "X" }, condItemCounter)
        } else {
            setPricingConditionsData(prevState => prevState.filter(item => item.condItemCounter !== condItemCounter));
        }
    };

    const handleAdd = () => {
        dispatchMethod({
            type: DISPATCH_TYPES.UPDATE_PRICING_CONDITIONS_DATA,
            payload: {
                itemNumber: selectedItem?.PrItem,
                editedLines: pricingConditionsData
            }
        });
        handleClose();
    }

    return <Dialog open={true} maxWidth={"xl"} scroll={'paper'} closeAfterTransition={false}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
                <StyledDialogTitle>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: 'center' }}>
                            <span >Select Condtion Types</span>
                        </div>
                        <div style={{ display: 'flex', gap: "10px" }}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}>
                                <CloseIcon onClick={handleClose} style={{ color: 'orange' }}>close</CloseIcon>
                            </div>
                        </div>
                    </div>
                </StyledDialogTitle>
            </div>
            <div>
                <PricingConditionsTable
                    totalCount={pricingConditionsData?.length || 0}
                    data={pricingConditionsData?.filter(item => !item["Cond_del"]) || []}
                    addPriceConditionsItem={addPriceConditionsItem}
                    conditionTypesData={conditionTypesData || []}
                    currencySet={currencySet || []}
                    documentFlow={documentFlow}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                    selectedItem={selectedItem}
                />
            </div>
            <DialogActions style={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "10px", margin: "0 20px 20px 10px" }}>
                <ApplyButton
                    onClick={handleAdd}
                    variant="contained" color="primary">
                    Save
                </ApplyButton>
                <ApplyButton
                    onClick={handleClose}
                    variant="contained" color="primary">
                    Cancel
                </ApplyButton>
            </DialogActions>

        </div>
    </Dialog>
};