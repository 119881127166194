import {reduce} from 'lodash';
import {assoc, concat, isEmpty, merge, mergeDeepWith} from "ramda";
import {
  Contract,
  ContractNumber,
  ContractSource,
  ContractType,
  DraftContractSource, ExcelJobError, ExcelJobStatus,
  ValidationContractMessage
} from "./contract";
import {AxiosWrapper} from "../../../shared/utils/axios.wrapper";
import ODataClientWrapper, {List} from "../../../shared/utils/odataClient.wrapper";
import {newContractQuery, ODataQuery, PartialODataQuery} from "./query";
import {OrderDirection, SimpleODataFilter, SimpleODataOrder} from "../../../../lib/odata";
import {ApiService} from "../../../shared/services/api.service";
import {withSystemAlias} from "../../../shared/domains/auth/authentication.service";
import {User} from "../../../shared/domains/user/user";
import {Item, ItemStatus} from "../item/item";
import {ItemPrice} from "../itemPrice/itemPrice";
import {dateTimeFromFormat} from "../../../shared/utils/global";
import {SAPBoolean} from "../../../shared/domains/core/sapBoolean";
import {CodeNameTerm, ContractClmLog, ContractClmResultLog} from "./clm/contract.clm";
import {ProcSpecs} from "./mrp/procSpecs";

export interface ListResponse<T> {
  count: number;
  countPerServer?: {[key: string]: number};
  data: T[];
}

export enum IdNo {
  CONTRACT = "1", // GET/UPDATE HEADER
  ITEM = "2", // GET ITEM,
  ITEM_DELETE_BLOCK_COUNTER = 'HC', // GET ITEM DELETED / BLOCKED / TARGET_VALUE COUNTER
  DRAFT_ITEM_DELETE_BLOCK_COUNTER = 'HR', // GET DRAFT ITEM TARGET_VALUE
  ITEM_PRICE = "3", // GET ITEM PRICES
  FAVORITE = "4", // TOGGLE FAVORITES
  DRAFT = "5", // GET DRAFT CONTRACT & CLONE A CONTRACT & UPDATE CLONED CONTRACT/ITEMS
  DELETE_DRAFT = "6", // DELETION DRAFT HEADER/ITEMS
  PUBLISH_DRAFT = "7", // PUBLISH DRAFT CONTRACT
  VALIDATE_DRAFT = "8", // VALIDATE DRAFT CONTRACT
  FILTER_COMPANY = '15',
  FILTER_PURCH_GROUP = '16',
  FILTER_PURCH_ORG = '17',
  FILTER_DOC_TYPE = '18',
  FILTER_PLANT = '19',
  FILTER_MATERIAL_GROUP = '20',
  FILTER_DF_PURCH_ORG = '60',
  FILTER_DF_PURCH_GROUP = '61',
  FILTER_DF_PR_PLANT = '62',
  FILTER_DF_PO_COMPANY_CODE = '63',
  COMPETITIVE_BIDDING = '30', // Competitive bidding,
  PR_ITEM = '40', // PR Item
  PO_ITEM = '50', // PO Item,
  MRP = 'MP', // MRP Planner,
  P0 = 'P0', // MRP Planner Approval,
  P1 = 'P1', // MRP Reject,
  P2 = 'P2', // MRP Approve,
  MH = 'MH', //MRP Header,
  MI = 'MI', // Mrp Item,
  MD = 'MD', // Mrp Delete
  EX = 'EX', // Expired Price
  AP = 'AP', // Validity Period,
  SC = 'SC' // Scale Price
}

export class ContractService extends ApiService {
  private static updateCollection = "/api/sapdata/PostFromBody?query=IdSet";
  static pathForType(type: ContractType): string {
    switch (type) {
      case ContractType.RECENT:
        return "/api/sapdata/GetRecentContracts?query=";
      case ContractType.ACTIVE:
      case ContractType.FAVORITES:
      case ContractType.DRAFT:
      default:
        return "/api/sapdata/Get?query="
    }
  }

  static queryBuilder(query: ODataQuery = newContractQuery()): any {
    const resource = "ContractHeadSet";
    let filter: SimpleODataFilter = null;
    let order: SimpleODataOrder = null;
    let newQuery: PartialODataQuery = null;
    switch (query.type) {
      case ContractType.FAVORITES:
        filter = {field: 'Favourite', value: 'X'};
        newQuery = {resource, filters: [filter]};
        return mergeDeepWith(concat, query, newQuery);
      case ContractType.DRAFT:
        if  (query.source === DraftContractSource.CHANGES_REQUESTS) {
          const path = "/api/sapdata/GetMrpContract?query=";
          filter = {field: 'IdNo', value: IdNo.P0};
          newQuery = {resource, path, filters: concat(query.filters, [filter])};
          return merge(query, newQuery);
        } else {
          filter = {field: 'IdNo', value: IdNo.DRAFT};
          order = {field: "TimeStamp", direction: OrderDirection.DESC};
          newQuery = {resource, filters: [filter], orderBy: [order]};
          return mergeDeepWith(concat, query, newQuery);
        }
      case ContractType.MRP:
        const path = "/api/sapdata/GetMrpContract?query=";
        filter = {field: 'IdNo', value: IdNo.MRP};
        newQuery = {resource, path, filters: [filter]};
        return merge(query, newQuery);
      case ContractType.ACTIVE:
      case ContractType.RECENT:
      default:
        return assoc('resource', resource, query);
    }
  }

  private static fetchFromCLM(query: ODataQuery = newContractQuery()): Promise<ListResponse<Contract>> {
    return Promise.resolve({count: 0, data: []});
  }

  static async fetch(query: ODataQuery = newContractQuery()): Promise<ListResponse<Contract>> {
    switch (query.source) {
      case ContractSource.SAP:
      case ContractSource.CLM_CHILD:
      case DraftContractSource.CLONE_CONTRACT:
      case DraftContractSource.CHANGES_REQUESTS:
        return ContractService.fetchFromQuery(ContractService.queryBuilder(query), Contract.FromBackend, true, true);
      default:
        return ContractService.fetchFromCLM(query);
    }
  }

  static async fetchPerServers(query: {[key: string]: ODataQuery}): Promise<ListResponse<Contract>> {
    const payload = Object.keys(query).reduce((acc, key) => {
      return {...acc, [key]: ODataClientWrapper.get().fromQuery(ContractService.queryBuilder(query[key])).withMultiOrigin().count().relative(false)}
    }, {});
    const path = '/api/sapdata/PostPerServer';
    const response = await AxiosWrapper.post(path, payload);
    if (!isEmpty(response.data)) {
      return {
        count: response.data.d.__count,
        countPerServer: response.data.d.countPerServer,
        data: response.data.d.results.map(Contract.FromBackend)
      };
    }
    return List.empty();
  }

  static async fetchContract(contractId: ContractNumber): Promise<ListResponse<Contract>> {
    const resource = "ContractHeadSet";
    const contractFilter: SimpleODataFilter = {field: 'AgreementNo', value: contractId};
    const contractQuery: PartialODataQuery = {resource, filters: [contractFilter]};
    const fullContractQuery = mergeDeepWith(concat, newContractQuery(), contractQuery) as ODataQuery;
    return await ContractService.fetchFromQuery(fullContractQuery, Contract.FromBackend, true, true);
  }

  static async errorsForDraft(agreementNo: string, timestamp: string, refContractNo: ContractNumber, systemAlias: string): Promise<any> {
    const response = await ODataClientWrapper.get()
      .resource("ErrMsgSet")
      .withSystemAlias(systemAlias, true)
      .filter({field: "AgreementNo", value: agreementNo})
      .and({field: "TimeStamp", value: timestamp})
      .and({field: "RefAgreementNo", value: refContractNo})
      .count()
      .execute();

    return response.data.d.results.map(ValidationContractMessage.FromBackend);
  }

  static async clone(contract: Contract, copyFlags: boolean): Promise<Contract> {
    const cloneContractBody = ContractService.cloneContractBody(contract, copyFlags);
    const response = await AxiosWrapper.post(
      ContractService.updateCollection,
      cloneContractBody,
      {headers: withSystemAlias(contract.SystemAlias, {}, true)}
    );
    return response.data.d.IdToHead.results[0];
  }

  static async update(contract: Contract): Promise<any> {
    const bodyToUpdate = ContractService.updateBody(contract);
    const response = await AxiosWrapper.post(
      ContractService.updateCollection,
      bodyToUpdate,
      {headers: withSystemAlias(contract.SystemAlias, {}, true)}
    );
    return response.data;
  }

  static async updateDraft(contract: Contract): Promise<any> {
    const bodyToUpdate = ContractService.updateDraftContractBody(contract);
    const response = await AxiosWrapper.post(
      ContractService.updateCollection,
      bodyToUpdate,
      {headers: withSystemAlias(contract.SystemAlias, {}, true)}
    );
    return response.data;
  }

  static async deleteDraft(contract: Contract): Promise<any> {
    const bodyToUpdate = ContractService.deleteBody(contract);
    const response = await AxiosWrapper.post(
      ContractService.updateCollection,
      bodyToUpdate,
      {headers: withSystemAlias(contract.SystemAlias, {}, true)}
    );
    return response.data;
  }

  static async publishDraft(contract: Contract): Promise<any> {
    const bodyToUpdate = ContractService.publishDraftBody(contract);
    const response = await AxiosWrapper.post(
      ContractService.updateCollection,
      bodyToUpdate,
      {headers: withSystemAlias(contract.SystemAlias, {}, true)});
    return response.data.d.IdToHead.results[0];
  }

  static async submitMRP(contract: Contract, user: User): Promise<any> {
    const path = '/api/sapdata/PostMrpContract?query=IdSet';
    const bodyToUpdate = ContractService.submitMRPBody(contract, user);
    const response = await AxiosWrapper.post(path, bodyToUpdate, {headers: withSystemAlias(contract.SystemAlias, {}, true)});
    return response.data.d.IdToHead.results[0];
  }

  static async submitMRPForApproval(contract: Contract, originalContract: Contract, items: Item[], originalItems: Item[], approver: string, user: User): Promise<any> {
    const path = '/api/sapdata/PostMrpContract?query=IdSet';
    const bodyToUpdate = ContractService.submitMRPForApprovalBody(contract, originalContract, items, originalItems, approver, user);
    const response = await AxiosWrapper.post(path, bodyToUpdate, {headers: withSystemAlias(contract.SystemAlias, {}, true)});
    return response.data.d.IdToHead.results[0];
  }

  static async approveMrp(contract: Contract): Promise<Contract> {
    const path = '/api/sapdata/PostMrpContractApproved?query=IdSet';
    const approveMrpBody = ContractService.approveMrpBody(contract);
    const response = await AxiosWrapper.post(path, approveMrpBody, {headers: withSystemAlias(contract.SystemAlias, {}, true)});
    return response.data.d.IdToHead.results[0];
  }

  static async rejectMrp(contract: Contract, reason: string): Promise<Contract> {
    const path = '/api/sapdata/PostMrpContractRejected?query=IdSet';
    const rejectMrpBody = ContractService.rejectMrpBody(contract, reason);
    const response = await AxiosWrapper.post(path, rejectMrpBody, {headers: withSystemAlias(contract.SystemAlias, {}, true)});
    return response.data.d.IdToHead.results[0];
  }

  static async updateMrp(contract: Contract, user: User): Promise<any> {
    const path = '/api/sapdata/PostMrpContract?query=IdSet';
    const bodyToUpdate = ContractService.updateMRPBody(contract, user);
    const response = await AxiosWrapper.post(path, bodyToUpdate, {headers: withSystemAlias(contract.SystemAlias, {}, true)});
    return response.data.d.IdToHead.results[0];
  }

  static async deleteMrp(contract: Contract, user: User): Promise<any> {
    const path = '/api/sapdata/PostMrpContract?query=IdSet';
    const bodyToUpdate = ContractService.deleteMRPBody(contract, user);
    const response = await AxiosWrapper.post(path, bodyToUpdate, {headers: withSystemAlias(contract.SystemAlias, {}, true)});
    return response.data.d.IdToHead.results[0];
  }

  static async fetchProcSpecs(compCode: string, purchOrg: string): Promise<ProcSpecs[]> {
    const response = await ODataClientWrapper.get()
      .resource("ProcSpecsSet")
      .and({field: 'CompCode', value: compCode})
      .and({field: 'PurchOrganization', value: purchOrg})
      .execute();

    return response.data.d.results.map(ProcSpecs.fromBackend);
  }

  static async submitToCLM(user: User, contract: Contract, doNotPrint: boolean = false, isDeleted: boolean = false, items: Item[] = null, itemsPrice: ItemPrice[] = []): Promise<boolean> {
    const path = '/api/Contracts/SendContractToClm';
    const bodyToUpdate = ContractService.submitCLMBody(user, contract, items, itemsPrice, doNotPrint, isDeleted);
    if (bodyToUpdate) {
      await AxiosWrapper.post(path, bodyToUpdate);
      return true;
    }
    return Promise.resolve(false);
  }

  static async resubmitToCLM(contract: Contract): Promise<boolean> {
    const path = `/api/Contracts/ResendContractToClm?contractId=${contract.AgreementNo}`;
    const response = await AxiosWrapper.post(path);
    return response.data;
  }

  static async unlock(contract: Contract): Promise<boolean> {
    const path = `/api/Clm/Unlock/${contract.AgreementNo}`;
    const response = await AxiosWrapper.put(path);
    return response.data;
  }

  static async healthCheck(): Promise<boolean> {
    const path = `/api/Clm/ConnectionTest`;
    const response = await AxiosWrapper.get(path);
    return response.data;
  }

  static async downloadCSVCLM(user: User, contract: Contract, doNotPrint: boolean, items: Item[] = null, itemsPrice: ItemPrice[] = []): Promise<boolean> {
    const path = '/api/Contracts/ContractToClmDownloadAsCsv';
    const bodyToUpdate = ContractService.submitCLMBody(user, contract, items, itemsPrice, doNotPrint);
    const response = await AxiosWrapper.post(path, bodyToUpdate, { responseType: 'blob' });
    return response.data;
  }

  static async download(contract: Contract, contractSource: ContractSource): Promise<any> {
    let path = `/api/sapdata/GetContractAsExcel?contractId=${contract.AgreementNo}`;
    if (contractSource === ContractSource.CLM_CHILD) {
      path = `/api/sapdata/GetClmContractAsExcel?agreementNo=${contract.AgreementNo}`;
    }
    const response = await AxiosWrapper.get(path, {responseType: 'blob', headers: withSystemAlias(contract.SystemAlias, {}, true)});
    console.log(response)
    console.log(response.data)
    return response.data;
  }

  static async exportTemplate(): Promise<any> {
    const response = await AxiosWrapper.get(`/api/sapdata/GetContractExcelTemplate`, {responseType: 'blob'});
    return response.data;
  }

  static async upload(formData, isCreatingNew, contractSource: ContractSource): Promise<any> {
    let path = `/api/sapdata/UploadContractAsExcel?isCreatingNewContract=${isCreatingNew}`;
    if (contractSource === ContractSource.CLM_CHILD) {
      path = `/api/sapdata/UploadClmContractAsExcel`;
    }
    const response = await AxiosWrapper.post(path, formData);
    return response.data;
  }

  static async fetchExcelHasPendingJob(): Promise<boolean> {
    const response = await AxiosWrapper.get('/api/sapdata/HasPendigExcelUpload');
    return response.data;
  }

  static async fetchExcelJobStatuses(): Promise<ExcelJobStatus[]> {
    const response = await AxiosWrapper.get('/api/sapdata/GetPendingExcelStatuses');
    return response.data.map(ExcelJobStatus.FromBackend);
  }

  static async fetchExcelJobHistory(): Promise<ExcelJobStatus[]> {
    const response = await AxiosWrapper.get('/api/sapdata/GetExcelHistory');
    return response.data.map(ExcelJobStatus.FromBackend);
  }

  static async fetchExcelJobErrors(batchId): Promise<ExcelJobError[]> {
    const response = await AxiosWrapper.get('/api/sapdata/GetExcelErrorDetails', {params: {batchId: batchId}});
    const description =response.data.description;
    if (description) {
      const data = JSON.parse(description);
      return data.d.results.map(ExcelJobError.FromBackend);
    }
    return [];
  }

  static async toggleFav(contractNo: string, systemAlias: string, fav: SAPBoolean): Promise<number> {
    const addToFavoriteRequestBody = ContractService.updateFav(contractNo, fav);
    const response = await AxiosWrapper.post(
      ContractService.updateCollection,
      addToFavoriteRequestBody,
      {headers: withSystemAlias(systemAlias, {}, true)}
    );
    return response.data;
  }

  static async validateDraft(draftContract: Contract): Promise<Contract> {
    const bodyToUpdate = ContractService.validateBody(draftContract);
    const response = await AxiosWrapper.post(ContractService.updateCollection, bodyToUpdate);
    return response.data.d.IdToHead.results[0];
  }

  static async fetchIncoTerms(): Promise<CodeNameTerm[]> {
    const response = await AxiosWrapper.get('/api/Contracts/GetContractIncoterms');
    return response.data.map(CodeNameTerm.FromBackend);
  }

  static async fetchSupplierPaymentTerm(vendorNo: string, purchasingOrg: string): Promise<string> {
    const response = await AxiosWrapper.get('/api/amdrdata/PaymentTerm', {
      params: {vendorCode: vendorNo, purchasingOrg: purchasingOrg}
    });
    return response.data;
  }

  static async fetchClmLogs(contractNo: string): Promise<any> {
    const path = `/api/Contracts/GetContractClmLog?contractId=${contractNo}`;
    const response = await AxiosWrapper.get(path);
    return response.data.map(ContractClmLog.FromBackend);
  }

  static async fetchClmResponseLogs(contractNo: string): Promise<any> {
    const path = `/api/Contracts/GetContractClmResultLog?contractId=${contractNo}`;
    const response = await AxiosWrapper.get(path);
    return response.data.map(ContractClmResultLog.FromBackend);
  }

  private static updateFav(contractNo: ContractNumber, fav: SAPBoolean): any {
    return {
      results: {
        IdNo: IdNo.FAVORITE,
        DataChanged: SAPBoolean.TRUE,
        IdToFav: {
          results: [
            {
              IdNo: IdNo.FAVORITE,
              AgreementNo: contractNo,
              Favourite: fav
            }
          ]
        }
      }
    };
  }

  private static updateBody(contract: Contract): any {
    return {
      results: {
        IdNo: IdNo.CONTRACT,
        DataChanged: SAPBoolean.TRUE,
        IdToHead: {
          results: [
            {
              IdNo: IdNo.CONTRACT,
              AgreementNo: contract.AgreementNo,
              VendorNo: contract.VendorNo,
              VendorName: contract.VendorName,
              AgreementType: contract.AgreementType,
              Currency: contract.Currency,
              CompanyCode: contract.CompanyCode,
              PurchOrganization: contract.PurchOrganization,
              AgmtDate: contract.AgmtDate,
              ValidityStart: contract.ValidityStart,
              ValidityEnd: contract.ValidityEnd,
              DataChanged: SAPBoolean.TRUE
            }
          ]
        }
      }
    }
  }

  private static validateBody(contract: Contract): any {
    return {
      results: {
        IdNo: IdNo.VALIDATE_DRAFT,
        DataChanged: SAPBoolean.TRUE,
        IdToHead: {
          results: [
            {
              IdNo: IdNo.DRAFT,
              VendorNo: contract.VendorNo,
              AgreementType: contract.AgreementType,
              Currency: contract.Currency,
              CompanyCode: contract.CompanyCode,
              PurchOrganization: contract.PurchOrganization,
              AgmtDate: contract.AgmtDate,
              ValidityStart: contract.ValidityStart,
              ValidityEnd: contract.ValidityEnd,
              DataChanged: SAPBoolean.TRUE,
              TimeStamp: contract.TimeStamp,
              RefAgreementNo: contract.RefAgreementNo,
              AgreementNo: contract.AgreementNo,
            }
          ]
        }
      }
    }
  }

  private static deleteBody(contract: Contract): any {
    return {
      results: {
        IdNo: IdNo.DELETE_DRAFT,
        DataChanged: SAPBoolean.TRUE,
        IdToHead: {
          results: [
            {
              IdNo: IdNo.DELETE_DRAFT,
              RefAgreementNo: contract.RefAgreementNo,
              TimeStamp: contract.TimeStamp,
              AgreementNo: contract.AgreementNo,
              VendorNo: contract.VendorNo,
              VendorName: contract.VendorName,
              AgreementType: contract.AgreementType,
              Currency: contract.Currency,
              CompanyCode: contract.CompanyCode,
              PurchOrganization: contract.PurchOrganization,
              AgmtDate: contract.AgmtDate,
              ValidityStart: contract.ValidityStart,
              ValidityEnd: contract.ValidityEnd,
              DataChanged: SAPBoolean.TRUE
            }
          ]
        }
      }
    }
  }

  private static updateDraftContractBody(contract: Contract): any {
    return {
      results: {
        IdNo: IdNo.DRAFT,
        DataChanged: SAPBoolean.TRUE,
        IdToHead: {
          results: [
            {
              IdNo: IdNo.DRAFT,
              VendorNo: contract.VendorNo,
              AgreementType: contract.AgreementType,
              TimeStamp: contract.TimeStamp,
              Currency: contract.Currency,
              CompanyCode: contract.CompanyCode,
              PurchOrganization: contract.PurchOrganization,
              AgmtDate: contract.AgmtDate,
              ValidityStart: contract.ValidityStart,
              ValidityEnd: contract.ValidityEnd,
              DataChanged: SAPBoolean.TRUE,
              RefAgreementNo: contract.RefAgreementNo,
              AgreementNo: contract.AgreementNo,
            }
          ]
        }
      }
    }
  }

  private static cloneContractBody(contract: Contract, copyFlags): any {
    return {
      results: {
        IdNo: IdNo.DRAFT,
        DataChanged: SAPBoolean.TRUE,
        CopyDeleted: copyFlags ? SAPBoolean.TRUE : SAPBoolean.FALSE,
        IdToHead: {
          results: [
            {
              IdNo: IdNo.DRAFT,
              VendorNo: contract.VendorNo,
              AgreementType: contract.AgreementType,
              TimeStamp: contract.TimeStamp,
              Currency: contract.Currency,
              CompanyCode: contract.CompanyCode,
              PurchOrganization: contract.PurchOrganization,
              AgmtDate: contract.AgmtDate,
              ValidityStart: contract.ValidityStart,
              ValidityEnd: contract.ValidityEnd,
              DataChanged: SAPBoolean.TRUE,
              RefAgreementNo: contract.AgreementNo,
              AgreementNo: "",
            }
          ]
        }
      }
    };
  }

  private static publishDraftBody(contract: Contract): any {
    return {
      results: {
        IdNo: IdNo.PUBLISH_DRAFT,
        DataChanged: SAPBoolean.TRUE,
        IdToHead: {
          results: [
            {
              IdNo: IdNo.PUBLISH_DRAFT,
              VendorNo: contract.VendorNo,
              AgreementType: contract.AgreementType,
              Currency: contract.Currency,
              CompanyCode: contract.CompanyCode,
              PurchOrganization: contract.PurchOrganization,
              AgmtDate: contract.AgmtDate,
              ValidityStart: contract.ValidityStart,
              ValidityEnd: contract.ValidityEnd,
              DataChanged: SAPBoolean.TRUE,
              TimeStamp: contract.TimeStamp,
              RefAgreementNo: contract.RefAgreementNo,
              AgreementNo: contract.AgreementNo,
            }
          ]
        }
      }
    }
  }

  private static submitMRPBody(contract: Contract, user: User): any {
    return {
      results: {
        IdNo: IdNo.MRP,
        DataChanged: SAPBoolean.TRUE,
        IdToHead: {
          results: [
            {
              IdNo: IdNo.MRP,
              VendorNo: contract.VendorNo,
              AgreementType: contract.AgreementType,
              Currency: contract.Currency,
              CompanyCode: contract.CompanyCode,
              PurchOrganization: contract.PurchOrganization,
              AgmtDate: contract.AgmtDate,
              ValidityStart: contract.ValidityStart,
              ValidityEnd: contract.ValidityEnd,
              DataChanged: SAPBoolean.TRUE,
              TimeStamp: contract.TimeStamp,
              RefAgreementNo: contract.RefAgreementNo,
              AgreementNo: contract.AgreementNo,
              User: user.accountName.toUpperCase()
            }
          ]
        }
      }
    }
  }

  private static submitMRPForApprovalBody(contract: Contract, originalContract: Contract, items: Item[], originalItems: Item[], approver: string, user: User): any {
    return {
      results: {
        IdNo: IdNo.P0,
        DataChanged: SAPBoolean.TRUE,
        IdToHead: {
          results: [
            {
              IdNo: IdNo.P0,
              AgreementNo: contract.AgreementNo,
              User: user.accountName.toUpperCase(),
              MRPApprover: approver
            }
          ]
        }
      },
      original: {
        startDate: originalContract.ValidityStart,
        endDate: originalContract.ValidityEnd,
        items: originalItems.map(i => ({item: i.ItemNo, quantity: i.TargetQty}))
      },
      changed: {
        startDate: contract.ValidityStart,
        endDate: contract.ValidityEnd,
        items: items.map(i => ({item: i.ItemNo, quantity: i.TargetQty}))
      }
    }
  }

  private static updateMRPBody(contract: Contract, user: User): any {
    return {
      results: {
        IdNo: IdNo.MH,
        DataChanged: SAPBoolean.TRUE,
        IdToHead: {
          results: [
            {
              IdNo: IdNo.MH,
              AgreementNo: contract.AgreementNo,
              ValidityStart: contract.ValidityStart,
              ValidityEnd: contract.ValidityEnd,
              DataChanged: SAPBoolean.TRUE,
              User: user.accountName.toUpperCase()
            }
          ]
        }
      }
    }
  }

  private static deleteMRPBody(contract: Contract, user: User): any {
    return {
      results: {
        IdNo: IdNo.MD,
        DataChanged: SAPBoolean.TRUE,
        IdToHead: {
          results: [
            {
              IdNo: IdNo.MD,
              AgreementNo: contract.AgreementNo,
              ValidityStart: contract.ValidityStart,
              ValidityEnd: contract.ValidityEnd,
              DataChanged: SAPBoolean.TRUE,
              User: user.accountName.toUpperCase()
            }
          ]
        }
      }
    }
  }

  private static approveMrpBody(contract: Contract): any {
    return {
      results: {
        IdNo: IdNo.P2,
        DataChanged: SAPBoolean.TRUE,
        IdToHead: {
          results: [
            {
              IdNo: IdNo.P2,
              AgreementNo: contract.AgreementNo,
              DataChanged: SAPBoolean.TRUE,
              User: contract.MRPPlanner.toUpperCase(),
              MRPPlanner: contract.MRPPlanner,
              ValidityStart: contract.ValidityStart,
              ValidityEnd: contract.ValidityEnd
            }
          ]
        }
      }
    }
  }

  private static rejectMrpBody(contract: Contract, reason: string): any {
    return {
      results: {
        IdNo: IdNo.P1,
        DataChanged: SAPBoolean.TRUE,
        IdToHead: {
          results: [
            {
              IdNo: IdNo.P1,
              AgreementNo: contract.AgreementNo,
              DataChanged: SAPBoolean.TRUE,
              User: contract.MRPPlanner.toUpperCase(),
              MRPPlanner: contract.MRPPlanner,
              MRPApprover: contract.MRPApprover,
              Reason: reason
            }
          ]
        }
      }
    }
  }

  private static submitCLMBody(user: User, contract: Contract, items: Item[], itemsPrice: {[key: string]: any} = {}, doNotPrint: boolean, isDeleted: boolean = false) {
    let changes: any = {};
    const initialExpiryDate = dateTimeFromFormat(contract.InitialExpiryDate);
    if (contract) {
      changes = {
        ChangedBy: user.accountName,
        ModifiedAt: new Date(),
        IsHeader: true,
        IsDelete: isDeleted,
        AgreementNo: contract.AgreementNo,
        ValidityStart: dateTimeFromFormat(contract.ValidityStart).toFormat('yyyy-MM-dd'),
        ValidityEnd: dateTimeFromFormat(contract.ValidityEnd).toFormat('yyyy-MM-dd'),
        InitialExpiryDate: (initialExpiryDate && !initialExpiryDate.invalid) ? initialExpiryDate.toFormat('yyyy-MM-dd') : '',
        PaymentTerms: contract.PaymentTerms,
        Incoterm1: contract.Incoterm,
        Incoterm2: contract.IncotermDesc,
        StrategicBuyer: contract.StrategicBuyer,
        NVC: contract.NVC,
        PurchGroup: contract.PurchGroup,
        DoNotPrint: doNotPrint
      };
    }

    if (items) {
      changes = {
        ...changes,
        Items: Object.keys(items).map(key => ({
            IsNew: false,
            ItemNo: items[key].ItemNo,
            IsDelete: items[key].Action === ItemStatus.DELETE,
            IsChange: true,
            ProductId: items[key].Material,
            TargetQty: items[key].TargetQty,
            OrderUnit: items[key].OrderUnit,
            PlantId: items[key].Plant,
            Currency: items[key].Currency,
            Per: items[key].Per,
            OrderPriceUnit: items[key].OrderPriceUnit,
            ItemPrice: reduce(Object.keys(itemsPrice).filter(itemNo => itemNo === key).map(key => itemsPrice[key]), (prev, prices) => {
              return [
                ...prev,
                ...prices.map(price => ({
                  ConditionItem: price.ConditionItem,
                  ConditionRecord: price.ConditionRecord,
                  NetPrice: price.Amount,
                  Per: price.Per,
                  ValidFrom: dateTimeFromFormat(price.ValidFrom).toFormat('yyyy-MM-dd'),
                  ValidTo: dateTimeFromFormat(price.ValidTo).toFormat('yyyy-MM-dd'),
                  IsNew: price.IsNew,
                  Awarded: true
                }))
              ]
            }, [])
          })
        )
      }
    }

    if (!isEmpty(changes)) return [{...changes}];
    return null;
  }
}
