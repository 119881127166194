import React, { useState } from 'react';
import { Button, Grid, MuiThemeProvider } from '@material-ui/core';
import { StyledDialog, StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'src/app/shared/components/layout/styles';
import { SettingsTheme } from 'src/app/shared/containers/dashboard/components/addCompanyCode/addCompanyCode.styles';
import { DocFlowItem } from 'src/app/docFlows/domains/prpo/poItem';
import { DISPATCH_TYPES } from '../../EditRequestAllLinesWrapper';

export const EditRequestDeletePopUp = ({ open, setOpen, toBeDeleteditems, dispatchMethod }: { open: boolean, setOpen: any, toBeDeleteditems: DocFlowItem[], dispatchMethod: any }) => {

    const handleSave = (e: any) => {
        e.preventDefault();
        setOpen(false);
        dispatchMethod({
            type: DISPATCH_TYPES.MASS_DELETE_ITEMS,
            payload: {
                deletedItems: toBeDeleteditems,
            }
        })
    };

    const deleteText: string = (toBeDeleteditems.length > 1 ?
        "s " : " ") + toBeDeleteditems.map(item => item.PrItem.replace(/^0+/, '')).join(", ");

    return (
        <StyledDialog open={open} maxWidth={"xs"} scroll={'paper'} fullWidth={true} closeAfterTransition={false}>
            <MuiThemeProvider theme={SettingsTheme}>
                <StyledDialogTitle>Delete Item</StyledDialogTitle>
                <StyledDialogContent dividers={true}>
                    <Grid container>
                        <div style={{ fontSize: '1rem' }}>
                            {`Caution! The selected item${deleteText} will be deleted.`}
                        </div>
                    </Grid>
                </StyledDialogContent>
                <StyledDialogActions>
                    <Button color="primary" type="submit" onClick={handleSave}> Delete</Button>
                    <Button color="primary" onClick={(e) => setOpen(false)}> Cancel</Button>
                </StyledDialogActions>
            </MuiThemeProvider>
        </StyledDialog>
    );
};
