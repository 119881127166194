import { template } from "lodash";
import { IdNamePair } from "src/app/preferredSuppliers/domains/psl";
import { sapUrl } from "../../shared/utils/urlBuilders";
import { MrpScope } from "../containers/mrpForm/mrpForm.utils";

export enum MrpCatalogType {
  MANUAL = 0,
  WEB = 1,
  ARIBA = 2
}

export enum newMrpCatalogType {
  MANUAL = 0,
  Fairmarkit = 1
}

export enum MRPTabs {
  MAPPING_LIST = "MRP Mapping List",
  MY_TASKS = "My Tasks",
  MMD_PO_LONG_TEXT = "Master Data - Addi. Info."
}

export enum MRPFilterTabs {
  MAPPED = "Mapped",
  NOT_MAPPED = "Not Mapped"
}

export enum MRPStatus {
  "Factory Validated" = 1,
  "" = 0
}

export enum MRPExpiredWithin {
  "10 Days" = 10,
  "15 Days" = 15,
  "30 Days" = 30,
  "60 Days" = 60,
  "90 Days" = 90,
  "" = null
}
export const SelectExpiredWithin10Days = {
  value: MRPExpiredWithin["10 Days"],
  label: "10 Days"
};
export const SelectExpiredWithin15Days = {
  value: MRPExpiredWithin["15 Days"],
  label: "15 Days"
};
export const SelectExpiredWithin30Days = {
  value: MRPExpiredWithin["30 Days"],
  label: "30 Days"
};
export const SelectExpiredWithin60Days = {
  value: MRPExpiredWithin["60 Days"],
  label: "60 Days"
};
export const SelectExpiredWithin90Days = {
  value: MRPExpiredWithin["90 Days"],
  label: "90 Days"
};
export const SelectCatalogManual = {
  value: MrpCatalogType.MANUAL,
  label: "Manual"
};
export const SelectCatalogFairmarkit = {
  value: newMrpCatalogType.Fairmarkit,
  label: "Fairmarkit"
};
export const SelectCatalogWeb = { value: MrpCatalogType.WEB, label: "Web" };
export const SelectCatalogAriba = {
  value: MrpCatalogType.ARIBA,
  label: "Ariba"
};

export const SelectCatalogTypeOptions = [
  SelectCatalogManual,
  SelectCatalogWeb,
  SelectCatalogAriba
];

export const newSelectCatalogTypeOptions = [
  SelectCatalogManual,
  SelectCatalogFairmarkit
];

export const SelectStatusFactoryValidated = {
  value: MRPStatus["Factory Validated"],
  label: "Factory Validated"
};
export const SelectStatusNotvalidated = {
  value: MRPStatus[""],
  label: "Not Validated"
};
export const SelectScopeAboveMarket = {
  value: MrpScope.AboveMarket,
  label: "Above Market"
};
export const SelectScopeAtMarket = {
  value: MrpScope.AtMarket,
  label: "At Market"
};
export const SelectStatusOptions = [
  SelectStatusFactoryValidated,
  SelectStatusNotvalidated
];
export const SelectScopeOptions = [
  SelectScopeAboveMarket,
  SelectScopeAtMarket
];

export const SelectExpiredWithinOptions = [
  SelectExpiredWithin10Days,
  SelectExpiredWithin15Days,
  SelectExpiredWithin30Days,
  SelectExpiredWithin60Days,
  SelectExpiredWithin90Days,
];

export const GetCatalogTypeLabel = (value: string | number): string => {
  switch (parseInt(value as any)) {
    case MrpCatalogType.MANUAL:
      return "Manual";
    case MrpCatalogType.WEB:
      return "Web";
    case MrpCatalogType.ARIBA:
      return "Ariba";
    default:
      return value?.toString();
  }
};

export const newGetCatalogTypeLabel = (value: string | number): string => {
  switch (parseInt(value as any)) {
    case MrpCatalogType.MANUAL:
      return "Manual";
    case newMrpCatalogType.Fairmarkit:
      return "Fairmarkit";
    default:
      return value?.toString();
  }
};

export const GetSelectedCatalogType = (value: number) => {
  switch (value) {
    case MrpCatalogType.MANUAL:
      return SelectCatalogManual;
    case MrpCatalogType.WEB:
      return SelectCatalogWeb;
    case MrpCatalogType.ARIBA:
      return SelectCatalogAriba;
    default:
      return "";
  }
};

export const newGetSelectedCatalogType = (value: number) => {
  switch (value) {
    case MrpCatalogType.MANUAL:
      return SelectCatalogManual;
    case newMrpCatalogType.Fairmarkit:
      return SelectCatalogFairmarkit;
    default:
      return "";
  }
};

export const GetStatusType = (value: number | string) => {
  switch (value) {
    case 1:
    case "1":
      return SelectStatusFactoryValidated;
    case "0":
    case 0:
      return SelectStatusNotvalidated;
    default:
      return "";
  }
};
export const GetExpiredWithin = (value: number | null) => {
  switch (value) {
    case MRPExpiredWithin["10 Days"]:
      return SelectExpiredWithin10Days;
    case MRPExpiredWithin["15 Days"]:
      return SelectExpiredWithin15Days;
    case MRPExpiredWithin["30 Days"]:
      return SelectExpiredWithin30Days;
    case MRPExpiredWithin["60 Days"]:
      return SelectExpiredWithin60Days;
    case MRPExpiredWithin["90 Days"]:
      return SelectExpiredWithin90Days;
    default:
      return null;
  }
};
export const GetScopeType = (value: string) => {
  switch (value) {
    case MrpScope.AboveMarket:
      return SelectScopeAboveMarket;
    case MrpScope.AtMarket:
      return SelectScopeAtMarket;
    default:
      return "";
  }
};
const SapLinkTemplate = template(
  "https://<%=prefix%>.nestle.com:26001/irj/servlet/prt/portal/prtroot/pcd!3a!2f!2fportal_content!2ftemplates!2fiviews!2fsap_transaction_iview?System=<%=alias%>&TCode=<%=code%>&GuiType=WinGui&SAP_TransType=TXN&SAP_TechRoleName=CONTROL2007&SAP_Market=XXSAP_DummySystem=<%alias%>&SAP_Source=EXT&SAP_IViewDesc=ME33K&DynamicParameter=RM06E-EVRTN%3d<%=contract%>%26&AutoStart=true"
);

export interface PriceGridItem {
  price: string;
  units: string;
}

export interface PriceInfo {
  id: number;
  supplierId: string;
  supplierPartId: string;
  price: string;
  pricePer: number;
  currency: string;
  quantity: string;
  unitOfMeasure: string;
  itemDescription: string;
  fullDescription: string;
  errorComment: string;
  catalogName: string;
  date: string;
  validityStartDate: string;
  validityEndDate: string;
  priceGrid: PriceGridItem[];
}

export class Mrp {
  constructor(
    public readonly id: number,
    public readonly supplierId: string,
    public readonly supplierName: string,
    public readonly supplierPartId: string,
    public readonly supplierPartIdDescription: string,
    public readonly manufacturePart: string,
    public readonly upc: string,
    public readonly materialNumber: string,
    public readonly materialDescription: string,
    public readonly materialGroup: string,
    public readonly unspsc: string,
    public readonly taxCode: string,
    public readonly taxCodeDesc: string,
    public readonly purchasingOrgCode: string,
    public readonly purchasingOrgName: string,
    public readonly companyCode: string,
    public readonly companyName: string,
    public readonly plantCode: string,
    public readonly plantName: string,
    public readonly catalogName: string,
    public readonly buyerEmail: string,
    public readonly additionalContacts: string,
    public readonly catalogType: number,
    public readonly paymentTerms: string,
    public readonly paymentTermsDesc: string,
    public readonly incoTerm: string,
    public readonly incoTermDesc: string,
    public readonly createdByUserId: number,
    public readonly createdByUserName: string,
    public readonly lastUpdate: string,
    public readonly createdDate: string,
    public readonly modifiedBy: string,
    public readonly deleted: boolean,
    public readonly contractNumber: string,
    public readonly contractSystemAlias: string,
    public readonly contractSyncError: any,
    public readonly accountAssignmentCategory: any,
    public readonly glAccount: string,
    public readonly glAccountDescription: string,
    public readonly costCenter: string,
    public readonly costCenterDescription: string,
    public readonly batchId: number,
    public readonly priceInfo: PriceInfo,
    public readonly contractStartValidityDate: string,
    public readonly contractEndValidityDate: string,
    public readonly targetValue: string,
    public readonly purchasingGroup: string,
    public readonly displaySAPUrl: string,
    public readonly scope: MrpScope,
    public readonly plannedDeliveryTime: string,
    public readonly requestId: string,
    public readonly signedAgreementNumber: string,
    public readonly status: number,
    public readonly spendCategory: string,
    public readonly contractLanguage: string,
    public readonly priceListLink: string
  ) { }

  static PreparePriceGrid(priceGrid: PriceGridItem[] = []): string {
    const result = priceGrid
      .map(
        e =>
          `${e.units.trim().replace(/-|;/g, "")}-${e.price
            .trim()
            .replace(/-|;/g, "")}`
      )
      .join(";");
    if (result) {
      return `${result};`;
    }
    return null;
  }

  static FromBackend(data: any): Mrp {
    const prefix = sapUrl(process.env.REACT_APP_ENV.trim());
    const displaySAPUrl = data.contractSystemAlias
      ? SapLinkTemplate({
        prefix: prefix,
        alias: data.contractSystemAlias,
        code: "ME33K", //'ME33K' is always VIEW, and 'ME32K' is always EDIT.
        contract: data.contractNumber
      })
      : null;
    const parsedPriceInfo = {
      ...data.priceInfo,
      priceGrid: data.priceInfo?.priceGrid
        ? data.priceInfo.priceGrid
          .split(";")
          .map(e => {
            const parsed = e.split("-");
            return parsed.length === 2
              ? { units: parsed[0], price: parsed[1] }
              : null;
          })
          .filter(el => !!el)
        : []
    };
    return new Mrp(
      data.id,
      data.supplierId,
      data.supplierName,
      data.supplierPartId,
      data.supplierPartIdDescription,
      data.manufacturePart,
      data.upc,
      data.materialNumber,
      data.materialDescription,
      data.materialGroup,
      data.unspsc,
      data.taxCode,
      data.taxCodeDesc,
      data.purchasingOrgCode,
      data.purchasingOrgName,
      data.companyCode,
      data.companyName,
      data.plantCode,
      data.plantName,
      data.catalogName,
      data.buyerEmail,
      data.additionalContacts,
      data.catalogType,
      data.paymentTerms,
      data.paymentTermsDesc,
      data.incoTerm,
      data.incoTermDesc,
      data.createdByUserId,
      data.createdByUserName,
      data.lastUpdate,
      data.createdDate,
      data.modifiedBy,
      data.deleted,
      data.contractNumber,
      data.contractSystemAlias,
      data.contractSyncError,
      data.accountAssignmentCategory,
      data.glAccount,
      data.glAccountDescription,
      data.costCenter,
      data.costCenterDescription,
      data.batchId,
      parsedPriceInfo,
      data.contractStartValidityDate,
      data.contractEndValidityDate,
      data.targetValue,
      data.purchasingGroup,
      displaySAPUrl,
      data.scope,
      data.plannedDeliveryTime?.toString() || "",
      data.requestId,
      data.signedAgreementNumber,
      data.status,
      data.spendCategory,
      data.contractLanguage,
      data.priceListLink
    );
  }
}
