import React from "react";
import { EditDetailsTable, PRStyledCell } from "src/app/purchaseRequisition/PR.styles";
import MaterialTable, { Column } from "material-table";
import { TableLoadingComponent } from "src/app/shared/components/loading";
import { useTableStyles } from "../../../../dataTable/docFlow/docFlowTable.styles";
import { DocFlowItem } from "src/app/docFlows/domains/prpo/poItem";
import { ItemTableCellComponent } from "../../../itemTableCell.component";
import { CustomFastAsyncSelect, CustomFastDate, CustomFastInput, CustomFastSelect } from "src/app/shared/components/form";
import { MasterService } from "src/app/shared/domains/master/master.service";
import { DateTime } from "luxon";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import ItemDetailsMoreButton from "./itemDetailsMoreButton";
import { calcValueForCandex, fetchCurrencyConversion, getCondtions, isItCandexVendor, removeDecimal } from "../../helper";
import { FeaturesContext } from "src/app/shared/domains/core/pataflag.context";
import { Features } from "src/app/shared/domains/core/pataflag";
import { isNil } from "ramda";
import DeliveryAddressComponent from "./deliveryAddress.component";
import { SpenderComponent } from "./spender.component";
import { DISPATCH_TYPES } from "../../EditRequestAllLinesWrapper";
import { useFetchCountryRegion, useFetchTaxCode } from "src/app/shared/domains/master/master.hooks";
import { useFetchCurrencySetFromSAP } from "../../hooks/mulitlineEditPr.hooks";
import DocumentFlow from "src/app/docFlows/domains/documentFlow/documentFlow";
import { Formatter } from "src/app/shared/components/amount-formatter";
import { showAmountOrAmountWithIcon } from "src/app/docFlows/helpers/functions";
import { PlantComponent, UomComponent } from "./plant.component";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#4caf50"
        },
        secondary: {
            main: "#ff9100"
        }
    }
});


type newItemDetailsTableProps = {
    currencyDecimals: any;
    lineItems: DocFlowItem[];
    documentFlow: DocumentFlow;
    setSelectedData: React.Dispatch<any>;
    dispatchMethod: any;
    showCBbasedOnSupplierStatus: any;
    setFinalResendPoData: Function;
    finalResendPodata: any;
    disbaleItemDetails?: boolean;
    vertexData?: any;
};

export const NewItemDetailsTable = (props: newItemDetailsTableProps) => {
    const { docFlowTableClass, removeBorderForCheckBox } = useTableStyles({});
    const { hasFeature } = React.useContext(FeaturesContext);
    const firstLineItem = props.lineItems[0];
    const { disbaleItemDetails = false } = props;
    const fieldLevelConditions = getCondtions(firstLineItem);
    const { data: taxCodes } = useFetchTaxCode(firstLineItem?.CompCode || props.documentFlow.POCompanyCode, firstLineItem?.SystemAlias);
    // const { data: currencyDecimals } = useFetchCurrencyDecimals(props.documentFlow.SystemAlias);
    const { data: countryRegions } = useFetchCountryRegion();
    const { data: currencySet } = useFetchCurrencySetFromSAP(firstLineItem?.SystemAlias);
    const [decimal, setDecimal] = React.useState<number | null>(null);
    const [priceToBeEdited, setPriceToBeEdited] = React.useState<boolean>(false);
    const [quantityToBeEdited, setQuantityToBeEdited] = React.useState<boolean>(false);
    const [perUnitToBeEdited, setPerUnitToBeEdited] = React.useState<boolean>(false);
    const isItCandex = isItCandexVendor(props.documentFlow) && hasFeature(Features.RC_ADD_FROM_CANDEX);

    React.useEffect(() => {
        for (let i = 1; i <= props.lineItems.length; i++) {
            let checkBox: HTMLElement = document.querySelector(`#itemDetails > div > div > div > div > div > div > table > tbody > tr:nth-child(${i}) > td`)
            let spanElement: any = checkBox.children[0]
            checkBox.style.border = 'none';
            spanElement.style.marginLeft = '5px'
        }
    }, [props.lineItems.length])

    const applyPlantChanges = (value) => {
        props.dispatchMethod({
            type: DISPATCH_TYPES.COMMON_IN_ALL_LINE_ITEMS,
            payload: { ...value }
        });
    }

    const applyChanges = async (vals: any, itemNo: string) => {
        await delete vals.Spender_Resend;
        if (hasFeature(Features.RC_164177_SHOW_RESEND_PO_PDF_BUTTON_ON_CONDITIONS) && vals?.PrRequisitioner) {
            props.dispatchMethod({
                type: DISPATCH_TYPES.EDIT_LINE_ITEMS,
                payload: {
                    lineNo: itemNo,
                    editedValues: vals,
                    spendorChanged: true
                }
            })
        } else {
            props.dispatchMethod({
                type: DISPATCH_TYPES.EDIT_LINE_ITEMS,
                payload: {
                    lineNo: itemNo,
                    editedValues: vals
                }
            })
        }
    };

    const applyPriceChanges = (vals: any, itemNo: string) => {
        props.dispatchMethod({
            type: DISPATCH_TYPES.UPDATE_PRICE_FIELDS,
            payload: {
                lineNo: itemNo,
                editedValues: vals
            }
        })
    };

    const handleDeliveryDateChange = (value: any, item: DocFlowItem) => {
        let tempDate = DateTime.fromISO(value).toFormat("yyyyMMdd");
        if (tempDate === "Invalid DateTime") {
            tempDate = item.PrDeliveryDate
        };

        applyChanges({
            PrDeliveryDate: tempDate
        }, item.PrItem)
    };

    const handleCurrencyChanges = (currency: { label: string, value: string }) => {
        const tempDecimal: { currency: string, decimal: string } = props.currencyDecimals?.filter(curr => curr.currency === currency.value)[0];
        const finalDecimal = tempDecimal?.decimal ? parseInt(tempDecimal?.decimal) : null;
        setDecimal(finalDecimal);
        const newAllItems = props.lineItems.map(item => {
            let price = fieldLevelConditions.isItFO ? item.PrPriceRaw : item.PrNetPriceRaw;

            price = removeDecimal(price, finalDecimal);
            const finalprice = fieldLevelConditions.isItFO ? { PrPriceRaw: price } : { PrNetPriceRaw: price };
            const finalQty = { PrQuantityRaw: removeDecimal(item.PrQuantityRaw, finalDecimal) };
            const finalPerUnit = { PrNetPriceUnit: removeDecimal(item.PrNetPriceUnit, finalDecimal) };
            const newTotal: number = (price * finalQty.PrQuantityRaw) / finalPerUnit.PrNetPriceUnit;

            return { ...item, ...finalprice, ...finalQty, ...finalPerUnit, PrPrice: newTotal, PrCurrency: currency.value };
        })

        fetchCurrencyConversion(
            props.showCBbasedOnSupplierStatus,
            firstLineItem.Threshold_currency,
            currency.value
        ).then((result) => {
            if (!result.ToCurrency && !result.ToAmount) {
                // show some warning msg... like selected currency is unable to convert to
                // threshold currency (EUR), Kindly change the currency or else system will consider
                // default currency as threshold currency.
                console.log("Currency error");
                props.dispatchMethod({
                    type: DISPATCH_TYPES.HANDLE_CURRENCY_CHANGE,
                    payload: {
                        currencyConversionValue: 1,
                        currencyWarning: `Currency conversion failed for ${result.prCurrency}: System will consider only value without currency conversion`,
                        newAllItems: newAllItems
                    }
                });
            }

            else {
                props.dispatchMethod({
                    type: DISPATCH_TYPES.HANDLE_CURRENCY_CHANGE,
                    payload: {
                        currencyConversionValue: Number(result.ToAmount?.trim()) / result.amount,
                        currencyWarning: "",
                        newAllItems: newAllItems
                    }
                });
            }

        })
            .catch((e) => {
                props.dispatchMethod({
                    type: DISPATCH_TYPES.HANDLE_CURRENCY_CHANGE,
                    payload: {
                        currencyConversionValue: 1,
                        currencyWarning: "",
                        newAllItems: newAllItems
                    }
                });

            })


        // props.dispatchMethod({ type: DISPATCH_TYPES.HANDLE_CURRENCY_CHANGE, payload: newAllItems })
    };

    const handlePriceChanges = (value: any, item: DocFlowItem) => {
        let tempValue = value;
        if (!value || value <= 0) { tempValue = fieldLevelConditions.isItFO ? item.PrPriceRaw : item.PrNetPriceRaw };
        let finalPrice = removeDecimal(tempValue, decimal);
        let price = fieldLevelConditions.isItFO ? { PrPriceRaw: finalPrice } : { PrNetPriceRaw: finalPrice };
        const newTotal: number = (finalPrice * item.PrQuantityRaw) / item.PrNetPriceUnit;
        applyPriceChanges({ ...price, PrPrice: newTotal }, item.PrItem);
    };
    const handleQtyChanges = (value: any, item: DocFlowItem) => {
        let tempValue = value;
        if (!value || value <= 0) { tempValue = item.PrQuantityRaw };
        let finalQty = removeDecimal(tempValue, decimal);
        const price = fieldLevelConditions.isItFO ? item.PrPriceRaw : item.PrNetPriceRaw;
        const newTotal: number = (price * finalQty) / item.PrNetPriceUnit;
        applyPriceChanges({ PrQuantityRaw: finalQty, PrPrice: newTotal }, item.PrItem);
    };
    const handlePerUnitChanges = (value: any, item: DocFlowItem) => {
        let tempValue = value;
        if (!value || value <= 0) { tempValue = item.PrNetPriceUnit };
        let finalPerUnit = removeDecimal(tempValue, decimal);
        const price = fieldLevelConditions.isItFO ? item.PrPriceRaw : item.PrNetPriceRaw;
        const newTotal: number = (price * item.PrQuantityRaw) / finalPerUnit;
        applyPriceChanges({ PrNetPriceUnit: finalPerUnit, PrPrice: newTotal }, item.PrItem);
    };

    const checkIfMaterialDisabled = (item: any) => {
        const fieldLevelConditions = getCondtions(item);
        if (item?.isItCopiedLineItem) { // if its copied line , material should be disabled for easy request and 
            // for rest should be enabled irrespective of other conditions
            if (fieldLevelConditions.isItEasyRequestPR) { return true }
            else return false;
        }
        return fieldLevelConditions.isItFO || (!fieldLevelConditions.isItFO && fieldLevelConditions.isTherePO) ||
            (!fieldLevelConditions.isItFO && fieldLevelConditions.isThereGR) || (!fieldLevelConditions.isItFO && fieldLevelConditions.isThereIR)
    };

    const disableField = (isItMandatoryOrSoleSource: boolean, value: string) => {

        if (!isItMandatoryOrSoleSource) {
            return false
        }
        if (value) {
            return true
        }
        return false
    }

    function applyChange(value: any) {
        props.dispatchMethod({
            type: DISPATCH_TYPES.COMMON_IN_ALL_LINE_ITEMS,
            payload: { ...value, spendorChanged: true, status: 'T' }
        });
        setPoSendingOptionsData(value.Spender_Resend)
    }

    const setPoSendingOptionsData = (spender) => {
        const poSendingOptionsData = props.finalResendPodata.postData?.status ? { ...props.finalResendPodata.postData, Spender_Resend: spender } : {
            Requester_Resend: props.lineItems[0].Requester_Resend,
            Spender_Resend: spender,
            Supplier_Resend: props.lineItems[0].Supplier_Resend,
            Other_Resend: props.lineItems[0].Other_Resend,
            Hide_Price: props.lineItems[0].Hide_Price,
            Title_resend: props.lineItems[0].Title_resend
        }
        props.setFinalResendPoData({ "changedStatus": true, "postData": poSendingOptionsData })
    }

    const applyUomChanges = (value) => {
        props.dispatchMethod({
            type: DISPATCH_TYPES.COMMON_IN_ALL_LINE_ITEMS,
            payload: { ...value }
        });
    }

    const PRDetailColumns = (): Column<any>[] => {
        const columns = [
            {
                title: "#", sorting: false, field: "PrItem", cellStyle: { width: "2%" }, render: (item: DocFlowItem, i) => (
                    <ItemTableCellComponent
                        key={i}
                        values={[item.PrItem]}
                        hideLeadingZeros={true}
                    />
                )
            },
            {
                title: "Material", field: "PrMaterial", sorting: false, cellStyle: { width: "10%" }, render: (item: any, i) => {
                    let disabled = checkIfMaterialDisabled(item)
                    return <CustomFastAsyncSelect
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            valueContainer: (base, state) => ({ ...base, paddingLeft: 0 }),
                            indicatorSeparator: (base, state) => ({ ...base, display: 'none' }),
                            control: (base, state) => ({ ...base, backgroundColor: 'transparent', borderStyle: 'none', borderRadius: 0, borderBottom: disabled ? '1px solid hsl(0,0%,90%)' : '1px solid', marginBottom: 7 })
                        }}
                        menuPortalTarget={document.body}
                        isMulti={false}
                        onLoadLimit={2}
                        clearable={false}
                        onLoad={x =>
                            MasterService.fetchMaterial(x, [item.Plant], item.SystemAlias)
                        }
                        customDisplay={option =>
                            option.label ? `${option.value} - ${option.label}` : option.value
                        }
                        disabled={disabled}
                        initialValue={
                            item.PrMaterial
                                ? {
                                    value: item.PrMaterial.replace(/^0+/, "")
                                }
                                : {}
                        }
                        onChange={value =>
                            value
                                ? applyChanges({
                                    PrMaterial: value.value
                                }, item.PrItem)
                                : null
                        }
                        customTooltipMessage="Type to find the materials"
                    />
                }
            },
            {
                title: "Description", field: "PrItemTxt", cellStyle: { width: "12%" }, sorting: false, render: (item: DocFlowItem, i) => {
                    const fieldLevelConditions = getCondtions(item);
                    return <CustomFastInput
                        disabled={!fieldLevelConditions.isItZSIM || disbaleItemDetails}
                        type={"text"}
                        initialValue={item.PrItemTxt}
                        onChange={value => applyChanges({ PrItemTxt: value }, item.PrItem)}
                        maxInputLength={40}
                    />
                }
            },
            hasFeature(Features.RC_166206_INCOTERMS) ? {
                title: "Vendor Material No.", field: "VendorMaterial", cellStyle: { width: "11%" }, sorting: false, render: (item: DocFlowItem, i) => {
                    return <CustomFastInput
                        type={"text"}
                        initialValue={item.VendorMaterial}
                        onChange={value => applyChanges({ VendorMaterial: value }, item.PrItem)}
                        maxInputLength={35}
                    />
                }
            } : null,
            fieldLevelConditions.isItEasyRequestPR ? {
                title: "Tax Code", field: "Taxcode", sorting: false, cellStyle: { width: "7%" }, render: (item: DocFlowItem, i) => {
                    return <CustomFastSelect
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            valueContainer: (base, state) => ({ ...base, paddingLeft: 0 }),
                            indicatorSeparator: (base, state) => ({ ...base, display: 'none' }),
                            control: (base, state) => ({ ...base, backgroundColor: 'transparent', borderStyle: 'none', borderBottom: '1px solid', borderRadius: '0px', marginBottom: 7 })
                        }}
                        menuPortalTarget={document.body}
                        isMulti={false}
                        clearable={false}
                        customDisplay={option => option.value}
                        initialValue={{ value: item?.Taxcode }}
                        options={taxCodes}
                        onChange={value => applyChanges({ Taxcode: value?.value }, item.PrItem)}
                        disabled={disbaleItemDetails}
                    />
                }
            } : null,
            {
                title: "Plant", field: "Plant", sorting: false, cellStyle: { width: hasFeature(Features.RC_169651_PLANT_EDIT_SHOW) ? "11%" : "3%" },
                render: (item: any, i) => hasFeature(Features.RC_169651_PLANT_EDIT_SHOW) ? <PlantComponent plant={item.Plant} compCode={firstLineItem?.CompCode || props.documentFlow.POCompanyCode} zone={firstLineItem?.SystemAlias} applyChange={applyPlantChanges} /> : item.Plant
            },
            {
                title: "Delivery Date", field: "PrDeliveryDate", cellStyle: { width: "11%" }, sorting: false, render: (item: DocFlowItem, i) => (
                    <CustomFastDate
                        initialValue={DateTime.fromFormat(
                            item.PrDeliveryDate,
                            "yyyyMMdd"
                        ).toISO()}
                        onChange={value =>
                            handleDeliveryDateChange(value, item)
                        }
                        disabled={disbaleItemDetails}
                    />
                )
            },
            {
                title: "Delivery Address", field: "countryRegions", cellStyle: { width: "7%" }, sorting: false, render: (item: DocFlowItem, i) => (
                    <DeliveryAddressComponent disbaleItemDetails={disbaleItemDetails} countryRegions={countryRegions} lineItem={item} applyChanges={applyChanges} ></DeliveryAddressComponent>
                )
            },
            {
                title: "Spender", field: "RequsitionQty", sorting: false, cellStyle: { width: "5%" }, render: (item: any, i) => (
                    <SpenderComponent
                        disbaleItemDetails={disbaleItemDetails}
                        lineItem={item}
                        applyChanges={hasFeature(Features.RC_165911_SPENDOR_UPDATE_WITH_PO_SENDING) ? applyChange : applyChanges}
                    />
                )
            },
            {
                title: "Currency", field: "PrCurrency", sorting: false, cellStyle: { width: "7%" }, render: (item: any, i) => {
                    const fieldLevelConditions = getCondtions(item);
                    const isThereGRIR = fieldLevelConditions.isThereGR || fieldLevelConditions.isThereIR;
                    const disableCurrency = isThereGRIR ? true : item?.isItAddedFromPsl ? disableField(item?.isSupplierStatusMandatoryOrSoleSource, item?.PrCurrency) : fieldLevelConditions.isItFO;

                    return <CustomFastSelect
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            valueContainer: (base, state) => ({ ...base, paddingLeft: 0 }),
                            indicatorSeparator: (base, state) => ({ ...base, display: 'none' }),
                            control: (base, state) => ({ ...base, backgroundColor: 'transparent', borderStyle: 'none', borderRadius: 0, borderBottom: disableCurrency ? '1px solid hsl(0,0%,90%)' : '1px solid', marginBottom: 7 })
                        }}
                        menuPortalTarget={document.body}
                        isMulti={false}
                        clearable={false}
                        disabled={disableCurrency || disbaleItemDetails}
                        customDisplay={option => option.value}
                        initialValue={item.PrQuantityUom ? { value: item.PrCurrency } : null}
                        options={currencySet ? currencySet : []}
                        onChange={handleCurrencyChanges}
                    />
                }
            },
            {
                title: "Unit", field: "PrQuantityUom", sorting: false, cellStyle: { width: hasFeature(Features.RC_167776_UOM_EDIT_AND_SAVE) ? "10%" : "2%" },
                render: (item: any, i) => hasFeature(Features.RC_167776_UOM_EDIT_AND_SAVE) ? <UomComponent uom={item.PrQuantityUom} meterial={item.PrMaterial} applyChange={applyUomChanges} /> : item.PrQuantityUom
            },
            {
                title: "Price", field: "PrPriceRaw", sorting: false, cellStyle: { width: "7%" }, render: (item: any, i) => {

                    const fieldLevelConditions = getCondtions(item);
                    const priceValue = fieldLevelConditions.isItFO ? item?.PrPriceRaw : item?.PrNetPriceRaw;
                    const disablePrice = isItCandex ? true : item?.isItAddedFromPsl ? disableField(item?.isSupplierStatusMandatoryOrSoleSource, priceValue) : false;

                    return <div tabIndex={0} onBlur={() => !disablePrice && setPriceToBeEdited(prevState => !prevState)}>
                        {!priceToBeEdited && !disablePrice ?
                            <div onClick={() => setPriceToBeEdited(prevState => !prevState)} >
                                <div style={{ color: "hsl(0,0%,20%)", borderBottom: "1px solid #707070", paddingBottom: "9px" }}>
                                    <Formatter amount={fieldLevelConditions.isItFO ? item.PrPriceRaw : item.PrNetPriceRaw} />
                                </div>
                            </div> :
                            <div>
                                <CustomFastInput
                                    type={"number"}
                                    initialValue={fieldLevelConditions.isItFO ? item.PrPriceRaw : item.PrNetPriceRaw}
                                    // onChange={value => handlePriceChanges(calcValueForCandex(value, props.documentFlow, hasFeature(Features.RC_ADD_FROM_CANDEX)), item)}
                                    onChange={value => handlePriceChanges(value, item)}
                                    disabled={disablePrice || disbaleItemDetails}
                                />
                            </div>}
                    </div>

                }
            },
            {
                title: "Qty.", field: "PrQuantityRaw", sorting: false, cellStyle: { width: "5%" }, render: (item: DocFlowItem, i) => {
                    const fieldLevelConditions = getCondtions(item);
                    return <div tabIndex={0} onBlur={() => setQuantityToBeEdited(prevState => !prevState)}>
                        {!quantityToBeEdited && !fieldLevelConditions.isItFO ?
                            <div onClick={() => setQuantityToBeEdited(prevState => !prevState)} >
                                <div style={{ color: "hsl(0,0%,20%)", borderBottom: "1px solid #707070", paddingBottom: "9px" }}>
                                    <Formatter amount={item.PrQuantityRaw} />
                                </div>
                            </div> :
                            <div>
                                <CustomFastInput
                                    disabled={fieldLevelConditions.isItFO || disbaleItemDetails}
                                    type={"number"}
                                    initialValue={item.PrQuantityRaw}
                                    onChange={value => handleQtyChanges(calcValueForCandex(value, props.documentFlow, hasFeature(Features.RC_ADD_FROM_CANDEX)), item)}
                                />
                            </div>}
                    </div>
                }
            },
            {
                title: "Per", field: "PrNetPriceUnit", sorting: false, cellStyle: { width: "5%" }, render: (item: any, i) => {

                    const fieldLevelConditions = getCondtions(item);
                    const disablePer = isItCandex ? true : item?.isItAddedFromPsl ? disableField(item?.isSupplierStatusMandatoryOrSoleSource, item?.PrNetPriceUnit) : fieldLevelConditions.isItFO;

                    return <div tabIndex={0} onBlur={() => !disablePer && setPerUnitToBeEdited(prevState => !prevState)}>
                        {!perUnitToBeEdited && !disablePer ?
                            <div onClick={() => setPerUnitToBeEdited(prevState => !prevState)} >
                                <div style={{ color: "hsl(0,0%,20%)", borderBottom: "1px solid #707070", paddingBottom: "9px" }}>
                                    <Formatter amount={item.PrNetPriceUnit} />
                                </div>
                            </div> :
                            <div>
                                <CustomFastInput
                                    disabled={disablePer || disbaleItemDetails}
                                    type={"number"}
                                    initialValue={item.PrNetPriceUnit}
                                    onChange={value => handlePerUnitChanges(value, item)}
                                />
                            </div>}
                    </div>
                }
            },
            {
                title: "Sub Total", field: "PrPrice", sorting: false, cellStyle: { width: "6%" }, render: (item: DocFlowItem, i) => (
                    <div style={{ fontWeight: "bolder" }}>
                        {
                            hasFeature(Features.RC_166302_SHOW_US_TAX_CALCULATIONS) ?
                                showAmountOrAmountWithIcon(item, props.vertexData)
                                :
                                <Formatter amount={item.PrPrice} />
                        }
                    </div>
                )
            },
            {
                sorting: false,
                title: "",
                field: "", cellStyle: { width: "2%" },
                render: (item: DocFlowItem) => {
                    return (
                        !disbaleItemDetails &&
                        <ItemDetailsMoreButton lineItem={item} documentFlow={props.documentFlow} dispatchMethod={props.dispatchMethod}></ItemDetailsMoreButton>
                    );
                }
            }
        ];
        return columns

    };

    const theColumns = PRDetailColumns();
    const columnsNotNull = theColumns.filter(el => !isNil(el));

    return <EditDetailsTable className={`${docFlowTableClass} ${removeBorderForCheckBox}`}>
        <MuiThemeProvider theme={theme}>
            <MaterialTable
                style={{
                    boxShadow: "none",
                    zIndex: 999
                }}
                isLoading={!props.lineItems}
                columns={columnsNotNull}
                data={props.lineItems}
                components={{
                    OverlayLoading: () => <TableLoadingComponent />,
                    Cell: props => <PRStyledCell {...props} />
                }}
                onSelectionChange={dataClicked => {
                    props.setSelectedData(dataClicked)
                }
                }
                options={{
                    selection: disbaleItemDetails ? false : true,
                    actionsColumnIndex: -1,
                    paging: false,
                    toolbar: false,
                    rowStyle: {
                        border: "none",
                        padding: "10px 5px",
                        fontSize: ".8rem", color: "#7c7777",
                        whiteSpace: "nowrap",
                        height: "60px"
                    },
                    headerStyle: {
                        fontSize: ".8rem", fontWeight: "bold",
                        height: "60px",
                        padding: "10px 5px",
                        whiteSpace: "nowrap",
                        color: "#5D5858",
                        backgroundColor: "#F5F5F5",
                        borderBottom: 'none'
                    },

                }}
            />
        </MuiThemeProvider>
    </EditDetailsTable>
}